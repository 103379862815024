body
    margin: 0
    padding: 0
    height: 100%
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    overflow: hidden
    font-weight: 600

*
    font-family: 'Adobe Clean', sans-serif !important

#root
    width: 100%
    height: 100%

.color-change
    --spectrum-global-color-red-600: #f9634C
    --spectrum-accent-background-color-default: var(--spectrum-global-color-red-600)
    --spectrum-accent-background-color-hover: var(--spectrum-global-color-red-600)
    --spectrum-accent-color-1000: #72A6A6

    //! To fix NumberField width
    --spectrum-alias-single-line-width: var(--spectrum-global-dimension-size-700)

.close-icon
    position: absolute !important
    top: 75px
    right: 20px
    cursor: pointer
    z-index: 10
