.subscription-root {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.subscription-container {
  text-align: center;
  margin: auto;
}

.subscription-container h1 {
  text-align: center;
}

.unsubscribe-container .subscription-button {
  display: block;
  margin: 0 auto;
}

/* Buttons and links */
.subscription-button {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
.subscription-button:hover {
  filter: contrast(115%);
}
.subscription-button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
.subscription-button:disabled {
  opacity: 0.5;
  cursor: none;
}
.price-table-container {
  display: flex;
  box-shadow: 0 2px 5px 0 rgba(60, 66, 87, .08), 0 1px 1px 0 rgba(0, 0, 0, .12);
}

.price-table-container section {
  border-radius: 6px;
  padding: 32px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background-color: #ffffff;
}

.price-table-container section:first-of-type {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 1px solid #efefef;
}

.price-table-container section:last-of-type {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.price-table-container button {
  background-color: #F0F0F0;
  color: #000000;
  border-radius: 64px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.price-table-container button:hover {
  background-color: #d6d6d6;
  filter: none;
}

.price-table-container .name {
  color: #7F7F7F;
  font-size: large;
  margin-top: 8px;
  text-align: center;
}

.price-table-container .price {
  color: #000000;
  font-size: xx-large;
  font-weight: 600;
  margin-bottom: 8px;
  margin-top: 8px;
  text-align: center;
}

.price-table-container .duration {
  color: #969696;
  font-size: smaller;
  text-align: center;
}

.completed-view-section {
  background-color: white;
  border-radius: 16px;
  padding: 20px;
}

.subscription-redirection {
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.price {
    color: tomato;
}